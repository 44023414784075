import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { Component } from "react";
import { hotel as gnHotel } from "gn-shared";
import { appStore } from "../../store";
import SectionTitle from "../../elements/sectionTitle";
import "./dropdown.css";
import InfoIcon from "@material-ui/icons/Info";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiThemeProvider,
  Tooltip,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { rtTheme } from "./defs";
import { toast } from "react-toastify";
import { YieldSheetService } from "../../services";
import moment from "moment";
import CompSetsTable from "./CompSetsTable";
import { OtaApi } from "../../../api/ota";
import withRouter from "../../withRouter";
import { YieldApi } from "../../../api/yield";

export interface ICompSetProps {
  activeHotelId: any;
  router: any;
}
export interface ICompSetState {
  displayUpdateRateWarningOnsave: boolean;
  hotelIds: number;
  hotelDisplayName: string;
  otaCompSetLists: any;
  defaultCompSetSelected: string;
  hotelCompSetParseInt: any;
  selectedCompSet: any;
  defaultCompSet: any;
  promiseResolvedCompSet: any;
  finalCompSetList: any;
  isLoading: boolean;
  compSetsTableRef: any;
  OriginalCompSetList: any;
  childKey: number;
  compsetHotelNameList: any;
  newHotelNameValue: any;
  defaultOtaValue: any;
  selectedOtaHotelId: number;
  autoCompleteReset: number;
  isUpdated: boolean;
  hover: boolean;
  hotel: any;
  otaDropDownFlag: boolean;
  updatedSubscriptionId: number;
}
class NewCompSetConfig extends Component<ICompSetProps, ICompSetState> {
  //private compSetsTableRef = React.createRef<any>();

  hotel: any;
  otaCompSetList: any;

  constructor(props: any) {
    super(props);

    this.hotel = gnHotel(appStore.meta.config.hotels[this.props.activeHotelId]);

    this.otaCompSetList = [];

    this.state = {
      displayUpdateRateWarningOnsave: false,
      hotel: JSON.parse(JSON.stringify(this.hotel)),
      OriginalCompSetList: [],
      hotelIds: this.hotel.ota.compSet,
      hotelDisplayName: this.hotel.info.name,
      otaCompSetLists: this.otaCompSetList,
      defaultCompSetSelected: this.hotel.ota.defaultCompsetType,
      promiseResolvedCompSet: [],
      finalCompSetList: [],
      defaultCompSet: [
        { label: "RO", value: "RO" },
        { label: "BB", value: "BB" },
      ],
      hotelCompSetParseInt: [],
      selectedCompSet: this.hotel.ota.compSet,
      isLoading: true,
      compSetsTableRef: React.createRef(),
      childKey: 1,
      compsetHotelNameList: [],
      newHotelNameValue: {},
      defaultOtaValue: {},
      selectedOtaHotelId: this.hotel.ota.hotelId,
      autoCompleteReset: 1,
      isUpdated: false,
      hover: false,
      otaDropDownFlag: false,
      updatedSubscriptionId: this.hotel.ota.subscriptionId,
    };
  }

  async componentDidMount() {
    await this.fetchCompset();
  }

  async fetchCompset() {
    try {
      YieldSheetService.revanista.hotel
        .getCompSetList(this.state.selectedOtaHotelId)
        .then((data: any) => {
          console.log("data", data);
          this.otaCompSetList = data;
        });

      let compsetHotelNameList = await OtaApi.getOtaHotelCompleteList();
      var otaHotelId = this.state.selectedOtaHotelId;
      let matchedOtaHotalDetails = compsetHotelNameList.find(
        (o: any) => Number(o.id) === Number(otaHotelId),
      );
      this.setState({
        promiseResolvedCompSet: this.otaCompSetList,
        defaultOtaValue: matchedOtaHotalDetails,
        compsetHotelNameList: compsetHotelNameList,
      });
    } catch (err) {
      throw err;
    }

    let hotelCompSet =
      typeof this.state.hotel.ota.compSet == "undefined"
        ? []
        : this.state.hotel.ota.compSet;
    if (hotelCompSet.length > 0) {
      hotelCompSet = hotelCompSet.map(function (val: any) {
        return parseInt(val);
      });
    } else {
      hotelCompSet = [];
    }

    hotelCompSet = this.state.otaDropDownFlag ? [] : hotelCompSet;

    console.log("compset ticked", hotelCompSet);

    this.setState({ hotelCompSetParseInt: hotelCompSet });
    let otaCompSet = this.otaCompSetList; //otatable.json
    console.log("otaCompSet", otaCompSet);
    for (let i = 0; i < otaCompSet.length; i++) {
      let otaTest = hotelCompSet.includes(otaCompSet[i]["id"]);
      if (otaTest === true) {
        otaCompSet[i]["Active"] = true;
      } else {
        otaCompSet[i]["Active"] = false;
      }
      otaCompSet[i]["id"] = parseInt(otaCompSet[i]["id"]);
      otaCompSet[i]["serialNo"] = i + 1;
    }
    this.setState({ finalCompSetList: otaCompSet });
    console.log("final", otaCompSet);

    for (let i = 0; i < otaCompSet.length; i++) {
      const updatedDate = await OtaApi.getOtaHotelUpdatedDate(otaCompSet[i].id);
      this.setState((prevState) => ({
        finalCompSetList: prevState.finalCompSetList.map(
          (item: any, index: number) => {
            if (index === i) {
              return { ...item, updatedDate: updatedDate };
            }
            return item;
          },
        ),
      }));
    }
    this.setState({ isLoading: false });
  }

  //Logic to get default compset from compset dropdown
  handleDefaultCompSet = (event: any) => {
    let selectedValue = event.target.value || event.target.selected;
    this.setState((prevState) => ({
      hotel: {
        ...prevState.hotel,
        ota: {
          ...prevState.hotel.ota,
          defaultCompsetType: selectedValue,
          compSet: this.state.selectedCompSet,
        },
      },
    }));
    this.setState({
      defaultCompSetSelected: selectedValue,
    });
  };

  //callback function to get child class state into parent class
  handleCallback = (childData: any) => {
    this.setState({ selectedCompSet: childData });
  };

  //save the compset config
  saveConfig = async () => {
    let newHotel = this.state.hotel;
    newHotel.ota.defaultCompsetType = this.state.defaultCompSetSelected;
    newHotel.ota.compSet = this.state.selectedCompSet;

    this.setState({ hotel: newHotel });
    if (this.state.selectedCompSet.length < 1) {
      console.log(
        "length this.state.selectedCompSet",
        typeof this.state.selectedCompSet,
      );
      toast.error("Please select at least one hotel");
      return;
    }
    if (this.state.otaDropDownFlag === true) {
      newHotel.ota.hotelId = this.state.selectedOtaHotelId.toString();
      newHotel.ota.compSet = this.state.hotelCompSetParseInt;
      newHotel.ota.subscriptionId = this.state.updatedSubscriptionId;
      this.setState({ hotel: newHotel });
    }

    toast.promise(
      YieldApi.updateHotelConfig({
        hotel: newHotel,
        start: moment().format("YYYY-MM-DD"),
      }),
      {
        pending: "Saving Competitive Set Config changes...",
        success: "Competitive Set Config changes saved", // Success message
        error: "Error while saving Competitive Set Config changes", // Error message
      },
    );
  };

  cancelChanges = async () => {
    this.setState({ otaDropDownFlag: false });
    let defaultCompsetType = this.hotel.ota.defaultCompsetType;
    let otaHotelId = this.hotel.ota.hotelId;

    this.setState({
      defaultCompSetSelected: defaultCompsetType,
      isLoading: true,
    });
    this.setState({ selectedOtaHotelId: this.hotel.ota.hotelId }, async () => {
      await this.fetchCompset();
      let matchedOtaHotalDetails = this.state.compsetHotelNameList.find(
        (o: any) => Number(o.id) === Number(otaHotelId),
      );

      this.setState({
        promiseResolvedCompSet: this.otaCompSetList,
        defaultOtaValue: matchedOtaHotalDetails,
      });
      let hotelCompSet = this.hotel.ota.compSet;
      hotelCompSet = hotelCompSet.map(function (val: any) {
        return parseInt(val);
      });
      this.setState({ hotelCompSetParseInt: hotelCompSet });

      let otaCompSet = this.state.promiseResolvedCompSet; //otatable.json
      for (let i = 0; i < otaCompSet.length; i++) {
        let otaTest = hotelCompSet.includes(otaCompSet[i]["id"]);
        if (otaTest === true) {
          otaCompSet[i]["Active"] = true;
        } else {
          otaCompSet[i]["Active"] = false;
        }
        otaCompSet[i]["id"] = parseInt(otaCompSet[i]["id"]);
        otaCompSet[i]["serialNo"] = i + 1;
      }
      this.setState({
        OriginalCompSetList: otaCompSet,
        isLoading: false,
        childKey: this.state.childKey + 1,
      });
    });
  };

  refreshOta = async (e: any) => {
    this.setState({ isLoading: true });
    const req = {
      hotelId: this.props.activeHotelId,
      start: moment().format("YYYY-MM-DD"),
      end: moment().add("1", "year").format("YYYY-MM-DD"),
    };
    YieldApi.ingestOta(req)
      .then(async () => {
        toast.warn("OTA is refreshing...");
        await this.fetchCompset();
      })

      .catch((err: any) => {
        console.log(err);
        toast.error("Error while refreshing OTA");
      });
  };

  handleOnMouseOver = () => {
    this.setState({ hover: true });
  };

  handleOnMouseOut = () => {
    this.setState({ hover: false });
  };

  handleChangeOnDropdown = async (selectedOtaHotelId: any) => {
    if (selectedOtaHotelId) {
      this.setState({ isLoading: true });
      this.setState(
        {
          selectedOtaHotelId: selectedOtaHotelId.id,
          otaDropDownFlag: true,
          updatedSubscriptionId: selectedOtaHotelId.subscription_id,
        },
        async () => {
          if (
            Number(selectedOtaHotelId.id) === Number(this.hotel.ota.hotelId)
          ) {
            this.setState({ otaDropDownFlag: false });
            this.cancelChanges();
            return;
          }
          await this.fetchCompset();
          this.setState({
            childKey: this.state.childKey + 1,
            hotelCompSetParseInt: [],
            selectedCompSet: [],
            isLoading: false,
          });
        },
      );
    }
  };

  render() {
    console.log(this.state.hotelCompSetParseInt);

    return (
      <MuiThemeProvider theme={rtTheme}>
        <Backdrop open={this.state.isLoading} invisible={true}>
          <CircularProgress color="inherit" id="circular-progress" />
        </Backdrop>
        <div>
          <br />
          <br />
          <br />
          <SectionTitle {...{ value: "Competitive List" }} />
          <br />
          <div style={{ flexGrow: "1", position: "relative" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  id="select-ota-hotel"
                  options={this.state.compsetHotelNameList}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("name")) {
                      return option.name;
                    }
                    return option;
                  }}
                  key={this.state.autoCompleteReset}
                  value={this.state.defaultOtaValue}
                  onChange={(event, selectedOtaHotelId) => {
                    this.handleChangeOnDropdown(selectedOtaHotelId);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select OTA Hotel Name"
                      fullWidth
                      style={{
                        width: "100%",
                      }}
                      variant="outlined"
                      name="OTAHotelId"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <Button
                  id="competitive-set-refresh"
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={this.refreshOta}
                  style={{ width: "50px", height: "70%", marginTop: "7px" }}
                >
                  <ion-icon name="refresh" style={{ fontSize: "20px" }} />
                </Button>
              </Grid>

              <Grid item xs={12} sm={5} md={3}>
                <TextField
                  value={this.state.defaultCompSetSelected}
                  style={{ width: "calc(100% - 30px)" }}
                  fullWidth
                  id="default-competitive-set"
                  select
                  label="Default Competitive Set"
                  variant="outlined"
                  name="defaultCompsetType"
                  onChange={this.handleDefaultCompSet}
                  // onBlur={this.onBlurOfInputFields.bind(this)}
                  // error={this.state.validateDefaultCompSet}
                  // helperText={this.state.validateDefaultCompSetMsg}
                >
                  <option value="oneGuestRO">1 Guest RO</option>
                  <option value="oneGuestBB">1 Guest BB</option>
                  <option value="RO">2 Guest RO</option>
                  <option value="BB">2 Guest BB</option>
                </TextField>
                <Tooltip
                  style={{
                    marginTop: "13px",
                    marginLeft: "8px",
                    marginRight: "4px",
                    position: "absolute",
                    right: "0px",
                  }}
                  title="Sets the default rate displayed in the competitive set."
                  arrow
                  placement="top"
                >
                  <InfoIcon
                    onMouseOver={this.handleOnMouseOver}
                    onMouseOut={this.handleOnMouseOut}
                    style={{
                      color: this.state.hover ? "blue" : "",
                      marginTop: "14px",
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          <br />
          {this.state.finalCompSetList.length > 0 &&
            this.state.isLoading === false && (
              <CompSetsTable
                key={this.state.childKey}
                ref={this.state.compSetsTableRef}
                items={this.state.finalCompSetList}
                originalItems={this.state.OriginalCompSetList}
                hotelCompSet={this.state.hotelCompSetParseInt}
                parentCallback={this.handleCallback}
              />
            )}
          <br />
          <div style={{ float: "right", marginBottom: "5vh" }}>
            <Button
              onClick={() => this.cancelChanges()}
              color="secondary"
              variant="contained"
              style={{
                backgroundColor: "#808080",
                color: "#ffffff",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              Cancel Changes
            </Button>
            <Button
              onClick={this.saveConfig}
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#ce0e29",
                color: "#ffffff",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(NewCompSetConfig);
