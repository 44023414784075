import { Component } from "react";
import { NavLink } from "react-router-dom";
import { Props, State } from "../models/navbar.models";
import { Navigate } from "react-router-dom";
import { appStore } from "./store";
import SettingsIcon from "@material-ui/icons/Settings";
import { Box, Modal, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ApartmentIcon from "@material-ui/icons/Apartment";
import withRouter from "./withRouter";
import { AdminApi } from "../api/admin";
import { computeRowProps } from "./yield-sheet/rowProp";

let logo: string;
import(`assets/images/${process.env.REACT_APP_STAGE}/navbar-logo.png`).then(
  (module) => {
    logo = module.default;
  },
);

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid var(--button-color);",
  boxShadow: 24,
  p: 4,
};

class Navbar extends Component<Props, State> {
  readonly state = {
    dropdownActive: false,
    onboardHotel: false,
    dropDownOpen: false,
    dropdownValue: "",
    inputValue: "",
    counter: 0,
  };

  componentDidMount() {
    window.addEventListener("click", this.closeDropdown);
    this.setState({ counter: this.state.counter + 1 });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeDropdown);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.dropdownValue !== this.state.dropdownValue) {
      this.setState({ dropDownOpen: false });
      const pathname = window.location.pathname;
      const part = pathname.split("/");
      const firstRoute = parseInt(part[1]);
      let hotel = appStore.meta.config.hotels[firstRoute];
      appStore.changeActiveHotel(hotel);
    }
  }

  onboardHotel = () => {
    this.setState({ onboardHotel: true });
  };

  handleOnLogout = () => {
    this.props.onLogout();
    setTimeout(() => {
      this.props.router.navigate("/");
    }, 500);
  };

  openHotelDropDown = () => {
    this.setState({ dropDownOpen: true });
  };

  async changeHotel(event: any) {
    if (event) {
      const target = event["id"];
      const loc = this.props.router.location.pathname;
      const parts = loc.split("/");
      const exceptId = parts
        .splice(2, parts.length - 1)
        .join("/")
        .toString();
      let hotel: any;
      if (typeof target === "number") {
        hotel = appStore.meta.config.hotels[target];
      } else {
        hotel = appStore.meta.config.hotels[target.hotelId];
      }
      this.props.router.navigate("/");
      this.props.router.navigate(`${target}/${exceptId}`);
      appStore.changeActiveHotel(hotel);
      appStore.requests.setAdmin("getHotelConfig", "fetching");
      AdminApi.getUsersHotelsMeta().then((response) => {
        let hotelTofetch: number[] = [];
        response.forEach((hotel: any) => {
          hotelTofetch.push(hotel.hotelId);
        });
        console.log("hohoh", hotelTofetch);

        console.log("navbar id", target);

        AdminApi.getHotelConfig(parseInt(target))
          .then(async (h: any) => {
            let hotels = new Map<number, any>();
            hotels.set(h.hotelId, h);
            appStore.meta.setConfig("hotels", hotels);
            let rowProps = new Map<number, any>();
            for (let id of hotels.keys()) {
              let h = hotels.get(id);
              console.log("h is ", h);
              var rowProp = await computeRowProps(h);
              rowProps.set(id, rowProp);
            }
            appStore.meta.setConfig("rowProps", rowProps);
            appStore.requests.setAdmin("getHotelConfig", "success");
            return response;
          })
          .catch((err: any) => {
            appStore.meta.setConfig("hotels", {});
            appStore.requests.setAdmin("getHotelConfig", "error");
            throw err;
          });
      });

      appStore.yieldSheet.outOfRange = new Set();

      this.setState({
        dropdownValue: event["label"],
        dropDownOpen: false,
      });
    }
  }

  render() {
    const dropdownClass = this.state.dropdownActive ? "show" : "";

    const hotelName = appStore.meta.user.hotels.map((data: any) => {
      return { label: data.name, id: data.hotelId };
    });

    const pathname = window.location.pathname;
    const part = pathname.split("/");
    const firstRoute = parseInt(part[1]);

    let allRoutes = ["/", "bulk-update", "calendar", "settings"];

    let settingsRoute = [
      "executive-summary",
      "user",
      "hotel-config",
      "rooms",
      "competitive-set",
    ];

    if (this.state.onboardHotel) {
      return <Navigate to="/onboardHotel" />;
    }

    console.log("route", part);

    if (part[2] && !allRoutes.includes(part[2])) {
      return (
        <Navigate
          to={`/${this.props.activeHotelId || appStore.activeHotel.hotelId}`}
        />
      );
    }

    if (part[3]) {
      if (part[2] !== "settings" && !settingsRoute.includes(part[3])) {
        return (
          <Navigate
            to={`/${this.props.activeHotelId || appStore.activeHotel.hotelId}`}
          />
        );
      }
    }

    const currentURL = this.props.router.location.pathname;

    return (
      <nav
        className="navbar navbar-expand navbar-dark bg-dark mb-4"
        style={{ position: "fixed", width: "100%", zIndex: "1000" }}
      >
        <NavLink to="" end>
          <img
            // onClick={this.handleOnHomePage}
            className="logo"
            src={logo}
            alt="Revanista Logo"
            id="navbar-revanista-logo"
          />
        </NavLink>
        {this.props.router.location.pathname !== "/" &&
        currentURL.toLowerCase() !== "/onboardhotel" ? (
          <span className="hotelName">
            {" " +
              appStore.meta.config.hotels[
                this.props.activeHotelId || appStore.activeHotel.hotelId
              ].info.fullName}
          </span>
        ) : (
          ""
        )}

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="revNavBar">
            {this.props.router.location.pathname !== "/" &&
            currentURL.toLowerCase() !== "/onboardhotel" ? (
              <>
                {hotelName.length > 1 && (
                  <li className="hotelDropdown">
                    <Box>
                      <ApartmentIcon
                        className="hotelIcon"
                        onClick={this.openHotelDropDown}
                        aria-label="Select Hotel"
                      />
                      <Modal
                        open={this.state.dropDownOpen}
                        onClose={() => this.setState({ dropDownOpen: false })}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={ModalStyle}>
                          <Autocomplete
                            style={{ width: "100%" }}
                            value={
                              appStore.meta.config.hotels[
                                this.props.activeHotelId ||
                                  appStore.activeHotel.hotelId
                              ].info.name
                            }
                            id="combo-box-demo"
                            options={hotelName}
                            getOptionSelected={(hotelName: any) =>
                              hotelName.label
                            }
                            getOptionLabel={(option) => {
                              if (option.hasOwnProperty("label")) {
                                return option.label;
                              }
                              return option;
                            }}
                            onChange={(event, hotelName) =>
                              this.changeHotel(hotelName)
                            }
                            inputValue={this.state.inputValue}
                            onInputChange={(event, newInputValue) =>
                              this.setState({ inputValue: newInputValue })
                            }
                            renderInput={(params: any) => (
                              <TextField {...params} label="Select hotel" />
                            )}
                          />
                        </Box>
                      </Modal>
                    </Box>
                  </li>
                )}
                {currentURL.toLowerCase() === "/onboardhotel" ? (
                  ""
                ) : currentURL.toLowerCase() === `/${firstRoute}` ? (
                  <li className="nav-item ml-auto">
                    <NavLink
                      to={`${firstRoute}/settings/executive-summary`}
                      end
                      className="nav-link"
                    >
                      <span style={{ color: "white" }}>
                        <SettingsIcon style={{ color: "#ce0e29" }} />{" "}
                        &nbsp;Settings
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  <li className="nav-item ml-auto">
                    <NavLink to={`${firstRoute}`} end className="nav-link">
                      <span style={{ color: "white" }}>
                        <ion-icon name="apps" />
                        &nbsp;Yield Sheet
                      </span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              ""
            )}
            <li className={`nav-item dropdown-bar${dropdownClass}`}>
              <a
                className="nav-link dropdown-toggle"
                id="userDropdown"
                role="button"
                onClick={this.toggleDropdown}
                style={{ color: "white" }}
              >
                <ion-icon name="contact" />
                {this.props.username}
              </a>
              <div
                className={`dropdown-menu dropdown-menu-right ${dropdownClass}`}
              >
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={this.handleOnLogout}
                >
                  Log out
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  private closeDropdown = (event: MouseEvent) => {
    if (!(event.target as HTMLElement).classList.contains("dropdown-toggle")) {
      this.setState({ dropdownActive: false });
    }
  };

  private toggleDropdown = () => {
    this.setState({ dropdownActive: !this.state.dropdownActive });
  };
}

export default withRouter(Navbar);
