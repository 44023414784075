import { Component, Fragment, RefObject, createRef } from "react";
import { hotel as gnHotel } from "gn-shared";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SectionTitle from "../../elements/sectionTitle";
import { CSSTransition } from "react-transition-group";
import EditRatePlanPanel from "./editRatePlanPanel";
import EditRoomPanel from "./editRoomPanel";
import {
  Container,
  Button,
  Divider,
  CardActions,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  MuiThemeProvider,
  IconButton,
  Tooltip,
  Box,
  Badge,
  TextField,
  Grid,
  FormControl,
  Chip,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { State, rtTheme, MenuProps } from "./defs";
import { toast } from "react-toastify";
import { ConfigService, YieldSheetService } from "../../services";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import "./roomStyle.css";
import { appStore } from "../../store";
import withRouter from "../../withRouter";
import Popup from "../../../reusable-components/popup/popup";

const emptyRp = {
  modeOfSale: "",
  id: "",
  longDescription: "",
  shortDescription: "",
  searchModeOfSale: "",
  applies: [],
};

const emptyRT = {
  shortDescription: "",
  id: "",
  minRate: "",
  maxRate: "",
  occupancy: "",
  childOccupancy: "",
  inventory: "",
  formula: "",
};

class RoomConfig extends Component<{ activeHotelId: any; router: any }> {
  private mainDivRefs: RefObject<HTMLDivElement>[];
  private mainDivRefsRT: RefObject<HTMLDivElement>[];
  readonly state: State = {
    roomData: JSON.parse(JSON.stringify(emptyRT)),
    isRTPanelOpened: false,
    isRTPanelNew: true,
    rateplanData: JSON.parse(JSON.stringify(emptyRp)),
    isRPPanelOpened: false,
    isRPPanelNew: false,
    isDeleteRpDialogOpened: false,
    isDeleteRtDialogOpened: false,
    rpToDelete: {},
    rtToDelete: {},
    hotel: {},
    unEditable: false,
    unEditableRP: false,
    isOverflow: [],
    isOverflowRT: [],
    ratePlanArray: [],
    roomTypeData: [],
    isAdmin: "",
    counter: 0,
    searchInput: "",
    searchDropDown: [],
    isDraging: true,
    rtUpdatedStatus: true,
    rpUpdatedStatus: true,
    rtUpdateData: {},
    previousLocation: "",
    key: 0,
    setRateFilter: "All",
    searchModeOfSale: "All",
  };
  currentUser: string;
  isAdmin: boolean;
  hotelIds: number[];
  hotelDisplayName: string;
  hotel: any;
  navigate: any;

  constructor(props: any) {
    super(props);
    console.log(this.props.activeHotelId);

    this.hotel = gnHotel(appStore.meta.config.hotels[this.props.activeHotelId]);

    this.currentUser = appStore.meta._user.username;
    this.isAdmin =
      appStore.meta._user.permissions.admin[0] === "*" ? true : false;

    this.hotelIds = [this.hotel.hotelId];
    this.state.hotel = this.hotel;
    this.hotelDisplayName = this.hotel.info.name;

    const rtArray = this.state.hotel.getRoomTypes().sort((a: any, b: any) => {
      return a.sortOrder - b.sortOrder;
    });
    let rtModArray: any = [];
    rtArray.forEach((rt: any, index: any) => {
      if (typeof rt.deleted == "undefined") {
        rtModArray.push(rtArray[index]);
      }
    });
    this.state.roomTypeData = rtModArray;

    const rts = rtModArray.map((rt: any) => rt.shortDescription);

    let rpsShortDescription = this.state.hotel
      .getRatePlans()
      .sort((a: any, b: any) => {
        return a.sortOrder - b.sortOrder;
      });

    let rpsModShortDescription: any = [];
    rpsShortDescription.forEach((rp: any, index: any) => {
      if (typeof rp.deleted == "undefined") {
        rpsModShortDescription.push(rpsShortDescription[index]);
      }
    });

    this.state.ratePlanArray = rpsModShortDescription;

    let rps = rpsModShortDescription.map((data: any) => data.shortDescription);
    this.mainDivRefs = rps.map(() => createRef<HTMLDivElement>());
    this.mainDivRefsRT = rts.map(() => createRef<HTMLDivElement>());
    this.state.isOverflow = rps.map(() => false);
    this.state.isOverflowRT = rts.map(() => false);
  }

  componentDidMount() {
    this.setState({ counter: this.state.counter + 1 });
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
  }

  onHotelUpdate = (hotel: any) => {
    ConfigService.revanista.hotel.updateConfig(hotel.hotelId, this.props);
  };

  closeRPPanel = () => {
    this.setState({ isRPPanelOpened: false });
  };
  closeRoomPanel = () => {
    this.setState({ isRTPanelOpened: false });
  };

  updateRPinStore = (rp: any) => {
    this.setState({
      rpUpdatedStatus: true,
    });
    const hotel = this.state.hotel;
    hotel.ratePlans[rp.id] = rp;
    this.onHotelUpdate(hotel);
  };

  updateRoomTypeinStore = (rp: any) => {
    this.setState({ rtUpdatedStatus: true });
    const hotel = this.state.hotel;
    hotel.roomTypes[rp.id] = rp;
    this.onHotelUpdate(hotel);
  };

  deleteRateplan = async (rp: any) => {
    let hotel = this.state.hotel;
    const encodedID = Buffer.from(rp.id, "binary").toString("base64");
    this.setState({ isDeleteRpDialogOpened: false });

    let toastDeleteRP = toast.promise(
      YieldSheetService.revanista.hotel.deleteRateplan(
        encodedID,
        hotel.hotelId,
      ),
      {
        pending:
          "Deleting RatePlan - '" + rp.shortDescription + "' in progress...",
        error: "Error deleting Rate Plan",
        success: "Rate Plan deleted",
      },
    );

    toastDeleteRP.then(() => {
      delete hotel.ratePlans[rp.id];
      this.onHotelUpdate(hotel);
      this.setState({ hotel });
    });
  };

  deleteRoomType = async (rt: any) => {
    let hotel = this.state.hotel;
    const encodedID = Buffer.from(rt.id, "binary").toString("base64");
    this.setState({ isDeleteRtDialogOpened: false });

    let toastDeleteRT = toast.promise(
      YieldSheetService.revanista.hotel.deleteRoomType(
        encodedID,
        hotel.hotelId,
      ),
      {
        pending:
          "Deleting Room Type - '" + rt.shortDescription + "' in progress...",
        error: "Error deleting Room Type",
        success: "Room Type deleted",
      },
    );

    toastDeleteRT.then(() => {
      delete hotel.roomTypes[rt.id];
      this.onHotelUpdate(hotel);
      this.setState({ hotel });
    });
  };

  openDeleteDialog = (rp: string) => {
    this.setState({
      rpToDelete: JSON.parse(JSON.stringify(rp)),
      isDeleteRpDialogOpened: true,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      isDeleteRpDialogOpened: false,
    });
  };

  openDeleteRtDialog = (rt: string) => {
    this.setState({
      rtToDelete: JSON.parse(JSON.stringify(rt)),
      isDeleteRtDialogOpened: true,
    });
  };

  closeDeleteRtDialog = () => {
    this.setState({
      isDeleteRtDialogOpened: false,
    });
  };

  openEditRPPanel = (rpId: string | null) => {
    let rp;
    let isNew = false;
    if (!rpId) {
      rp = JSON.parse(JSON.stringify(emptyRp));
      isNew = true;
    } else {
      rp = JSON.parse(JSON.stringify(this.state.hotel.ratePlans[rpId]));
    }
    this.setState({ unEditableRP: true });
    this.setState({
      rateplanData: rp,
      isRPPanelOpened: true,
      isRPPanelNew: isNew,
    });
  };

  openEditRPPanelByPlus = (rpId: string | null) => {
    let rp;
    let isNew = false;
    if (!rpId) {
      rp = JSON.parse(JSON.stringify(emptyRp));
      isNew = true;
    } else {
      rp = JSON.parse(JSON.stringify(this.state.hotel.ratePlans[rpId]));
    }
    this.setState({ unEditableRP: false });
    this.setState({
      rateplanData: rp,
      isRPPanelOpened: true,
      isRPPanelNew: isNew,
    });
  };

  openEditRoomPanel = (rtID: string | null) => {
    let rt;
    let isNew = false;
    if (!rtID) {
      rt = JSON.parse(JSON.stringify(emptyRT));
      isNew = true;
    } else {
      rt = JSON.parse(JSON.stringify(this.state.hotel.roomTypes[rtID]));
    }
    this.setState({ unEditable: true });
    this.setState({
      roomData: rt,
      isRTPanelOpened: true,
      isRTPanelNew: isNew,
    });
  };

  openEditRoomPanelByPlus = (rtID: string | null) => {
    let rt;
    let isNew = false;
    if (!rtID) {
      rt = JSON.parse(JSON.stringify(emptyRT));
      isNew = true;
    } else {
      rt = JSON.parse(JSON.stringify(this.state.hotel.roomTypes[rtID]));
    }
    this.setState({ unEditable: false });
    this.setState({
      roomData: rt,
      isRTPanelOpened: true,
      isRTPanelNew: isNew,
    });
  };

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.checkOverflow);
  }

  checkOverflow = () => {
    const updatedIsOverflow = this.mainDivRefs.map((ref) => {
      return (
        (ref.current && ref.current.scrollWidth > ref.current.clientWidth) ||
        (ref.current && ref.current.scrollHeight > ref.current.clientHeight)
      );
    });
    this.setState({ isOverflow: updatedIsOverflow });

    const updatedIsOverflowForRT = this.mainDivRefsRT.map((ref) => {
      return (
        (ref.current && ref.current.scrollWidth > ref.current.clientWidth) ||
        (ref.current && ref.current.scrollHeight > ref.current.clientHeight)
      );
    });
    this.setState({ isOverflowRT: updatedIsOverflowForRT });
  };

  getCardForRP = (RPOnly: any, index: any) => {
    let desc = RPOnly.shortDescription;
    let isRoomType = !RPOnly.applies;
    const { isOverflow } = this.state;

    return (
      <Card key={RPOnly.id}>
        <div className="badge">{index + 1}</div>
        <CardActionArea>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ fontSize: "1em", width: "80%" }}
            >
              <div
                ref={this.mainDivRefs[index]}
                className={
                  isOverflow[index] ? "message_ticker_new" : "message_ticker"
                }
              >
                {desc}
              </div>
            </Typography>
            <Fragment>
              <Tooltip title="Open">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={(event: any) => {
                    !isRoomType
                      ? this.openEditRPPanel(RPOnly.id)
                      : this.openEditRoomPanel(RPOnly.id);
                    event.stopPropagation();
                  }}
                  style={{ margin: "7px" }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
              {this.isAdmin && (
                <Tooltip title="Remove">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => {
                      this.openDeleteDialog(RPOnly);
                    }}
                  >
                    <DeleteIcon style={{ fill: "crimson" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  getCardForRT = (RTOnly: any, index: any) => {
    let desc = RTOnly.shortDescription;
    let isRoomType = !RTOnly.applies;
    const { isOverflowRT } = this.state;

    return (
      <Card key={RTOnly.id}>
        <div className="badge">{index + 1}</div>
        <CardActionArea>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ fontSize: "1em", width: "80%" }}
            >
              <div
                ref={this.mainDivRefsRT[index]}
                className={
                  isOverflowRT[index] ? "message_ticker_new" : "message_ticker"
                }
              >
                {desc}
              </div>
            </Typography>

            <Fragment>
              <Tooltip title="Open">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={(event: any) => {
                    this.state.isRTPanelOpened && !isRoomType
                      ? this.openEditRPPanel(RTOnly.id)
                      : this.openEditRoomPanel(RTOnly.id);
                    event.stopPropagation();
                  }}
                  style={{ margin: "7px" }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
              {this.isAdmin && (
                <Tooltip title="Remove">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    // component="span"
                    onClick={() => {
                      this.openDeleteRtDialog(RTOnly);
                    }}
                  >
                    <DeleteIcon style={{ fill: "crimson" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          </CardContent>
        </CardActionArea>
        <CardActions></CardActions>
      </Card>
    );
  };

  onChange = async (
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any,
  ) => {
    this.setState({ counter: this.state.counter + 1 });
    if (sourceId === "roomType") {
      let sortedRt = this.state.hotel.getRoomTypes().sort((a: any, b: any) => {
        return a.sortOrder - b.sortOrder;
      });
      const rt = sortedRt[sourceIndex];
      const rtId = sortedRt[sourceIndex].id;

      const nextState = swap(sortedRt, sourceIndex, targetIndex);

      this.updateRtSortOrder(rt, rtId, targetIndex, nextState);
    } else if (sourceId === "ratePlan") {
      console.log(sourceId, sourceIndex, targetIndex, targetId);

      let sortedRp = this.state.hotel.getRatePlans().sort((a: any, b: any) => {
        return a.sortOrder - b.sortOrder;
      });
      const rp = sortedRp[sourceIndex];
      const rpId = sortedRp[sourceIndex].id;

      const nextState = swap(sortedRp, sourceIndex, targetIndex);

      this.updateRpSortOrder(rp, rpId, targetIndex, nextState);
    }
  };

  updateRtSortOrder = async (
    rt: any,
    rtId: any,
    targetIndex: any,
    nextState: any,
  ) => {
    const hotel = this.state.hotel;
    hotel.roomTypes[rtId].sortOrder = targetIndex;
    this.setState({
      roomTypeData: nextState,
    });

    console.log("room type dta", this.state.roomTypeData);

    const dataObject: { [key: string]: any } = { roomType: {} };

    nextState.forEach((item: any, index: any) => {
      item.sortOrder = index;
      dataObject.roomType[item.id] = item;
    });

    try {
      this.setState({
        isRTPanelOpened: false,
        isDraging: false,
      });
      await YieldSheetService.revanista.hotel.writeRtSortOrder(
        dataObject,
        this.state.hotel.hotelId,
      );
      this.setState({ hotel });
      this.onHotelUpdate(hotel);
      this.setState({
        isRTPanelOpened: false,
        isDraging: false,
      });
    } catch (err) {
      console.log(err);
      toast.error("Error updating room type order");
    }
  };

  updateRpSortOrder = async (
    rp: any,
    rpId: any,
    targetIndex: any,
    nextState: any,
  ) => {
    const hotel = this.state.hotel;
    hotel.ratePlans[rpId].sortOrder = targetIndex;
    this.setState({
      ratePlanArray: nextState,
    });

    const dataObject: { [key: string]: any } = { ratePlan: {} };

    nextState.forEach((item: any, index: any) => {
      item.sortOrder = index;
      dataObject.ratePlan[item.id] = item;
    });

    try {
      await YieldSheetService.revanista.hotel.writeRpSortOrder(
        dataObject,
        this.state.hotel.hotelId,
      );
      this.setState({ hotel });
      this.onHotelUpdate(hotel);
    } catch (err) {
      console.log(err);
      toast.error("Error updating room type order");
    }
  };

  handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    const rtDetail = this.state.roomTypeData.map((data: any) => {
      return { label: data.shortDescription, value: data.id };
    });

    switch (name) {
      case "search":
        console.log("value", value);
        this.setState({
          searchInput: value,
        });
        break;

      case "roomType":
        const { searchDropDown } = this.state;

        if (value[value.length - 1] === "all") {
          let options = rtDetail.map((data: any) => data.value);

          this.setState({
            searchDropDown:
              this.state.roomTypeData.length === searchDropDown.length
                ? []
                : options,
          });

          return;
        }
        this.setState({
          searchDropDown: [...value],
        });
        break;

      case "searchModeOfSale":
        this.setState({
          searchModeOfSale: value,
        });
        break;

      case "setRateFilter":
        this.setState({
          setRateFilter: value,
        });
        break;

      default:
        break;
    }
  };

  render() {
    const { searchInput, searchDropDown, searchModeOfSale, setRateFilter } =
      this.state;

    const rtDetail = this.state.roomTypeData.map((data: any) => {
      return { label: data.shortDescription, value: data.id };
    });

    const filteredData = this.state.ratePlanArray.filter((item: any) => {
      const matchesSearchInput =
        searchInput.length === 0 ||
        item.shortDescription
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        item.id.toLowerCase().includes(searchInput.toLowerCase());

      const matchesModeOfSale =
        (searchModeOfSale === "All" && item) ||
        item.modeOfSale === searchModeOfSale;

      const matchSetRate =
        (setRateFilter === "All" && item) ||
        (setRateFilter === "set rate" && item.defaultRate !== 0) ||
        (setRateFilter === "without set rate" &&
          item.formula &&
          item.formula.length !== 0);

      const matchesSearchDropDown =
        (searchDropDown.length === 0 && item) ||
        (searchDropDown.includes("all") && item) ||
        searchDropDown.every((element: any) => item.applies.includes(element));

      return (
        matchesSearchInput &&
        matchesModeOfSale &&
        matchSetRate &&
        matchesSearchDropDown
      );
    });

    return (
      <MuiThemeProvider theme={rtTheme}>
        <GridContextProvider onChange={this.onChange}>
          <div>
            <nav className="d-flex mb-4">
              <h1 className="h5">{this.hotelDisplayName} Room Configuration</h1>
            </nav>
            <SectionTitle {...{ value: "Room Types" }} />
            <br />
            {!this.state.isRTPanelOpened && (
              <Button
                aria-label="split"
                variant="contained"
                color="primary"
                component="span"
                id="add-room-type"
                onClick={() => {
                  this.openEditRoomPanelByPlus(null);
                }}
                style={{ width: "50px", height: "100%", marginBottom: "10px" }}
              >
                <AddIcon />
              </Button>
            )}
            <br />
            <CSSTransition
              in={this.state.isRTPanelOpened}
              appear={true}
              exit={true}
              timeout={1000}
              classNames="fade"
              unmountOnExit
            >
              <EditRoomPanel
                key={this.state.roomData.id}
                hotel={this.state.hotel}
                roomType={this.state.roomData}
                allData={this.state.roomTypeData}
                onclose={this.closeRoomPanel}
                isNew={this.state.isRTPanelNew}
                onUpdate={this.updateRoomTypeinStore}
                unEditable={this.state.unEditable}
                counter={this.state.counter}
              ></EditRoomPanel>
            </CSSTransition>
            <Container>
              <MuiThemeProvider theme={rtTheme}>
                <GridDropZone
                  id="roomType"
                  boxesPerRow={4}
                  rowHeight={90}
                  disableDrag={!this.isAdmin}
                  disableDrop={!this.isAdmin}
                  // Remember to make it in round form
                  style={{
                    height: `${
                      Math.ceil(
                        ((this.state.roomTypeData.length / 4) * 100) / 100,
                      ) * 100
                    }px`,
                  }}
                >
                  {this.state.roomTypeData.map((rt: any, index: any) => (
                    <GridItem key={rt.id}>
                      <div>{this.getCardForRT(rt, index)}</div>
                    </GridItem>
                  ))}
                </GridDropZone>
              </MuiThemeProvider>
            </Container>

            <br />
            <Divider></Divider>
            <SectionTitle {...{ value: "Rate Plans" }} />
            <br />
            {!this.state.isRPPanelOpened && (
              <Button
                aria-label="split"
                variant="contained"
                color="primary"
                component="span"
                onClick={() => {
                  this.openEditRPPanelByPlus(null);
                }}
                style={{ width: "50px", height: "100%", marginBottom: "10px" }}
              >
                <AddIcon />
              </Button>
            )}
            <br />
            <CSSTransition
              in={this.state.isRPPanelOpened}
              appear={true}
              exit={true}
              timeout={1000}
              classNames="fade"
              unmountOnExit
            >
              <EditRatePlanPanel
                key={this.state.rateplanData.id}
                hotel={this.state.hotel}
                ratePlan={this.state.rateplanData}
                onclose={this.closeRPPanel}
                isNew={this.state.isRPPanelNew}
                onUpdate={this.updateRPinStore}
                unEditableRP={this.state.unEditableRP}
                counter={this.state.counter}
              ></EditRatePlanPanel>
            </CSSTransition>
            <Container>
              <MuiThemeProvider theme={rtTheme}>
                <br />
                <div>
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <TextField
                        style={{ width: "100%", background: "white" }}
                        fullWidth
                        id="outlined-basic"
                        label="Search"
                        variant="outlined"
                        name="search"
                        onChange={(e) => this.handleChange(e)}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-multiple-chip-label">
                          Room Type
                        </InputLabel>
                        <Select
                          style={{ width: "100%", background: "white" }}
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          label="Room Type"
                          name="roomType"
                          multiple
                          value={this.state.searchDropDown}
                          onChange={(e) => this.handleChange(e)}
                          renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {(this.state.searchDropDown as string[])
                                .filter((value: any) => value !== "all")
                                .map((value: any) => {
                                  const chip = rtDetail.find(
                                    (item: any) => item.value === value,
                                  );
                                  const label =
                                    typeof chip != "undefined"
                                      ? chip.label
                                      : "";

                                  if (label) {
                                    return (
                                      <Chip
                                        key={value}
                                        label={label}
                                        style={{ margin: "2px" }}
                                      />
                                    );
                                  }
                                })}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {rtDetail.map((rt: any) => (
                            <MenuItem key={rt.value} value={rt.value}>
                              <Checkbox
                                checked={
                                  this.state.searchDropDown.indexOf(rt.value) >
                                  -1
                                }
                              />

                              {rt.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl variant="outlined">
                        <TextField
                          value={this.state.searchModeOfSale}
                          style={{ width: "100%", background: "white" }}
                          fullWidth
                          select
                          label="Mode of sale"
                          variant="outlined"
                          name="searchModeOfSale"
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value="All">All</option>
                          <option value="OTA">OTA</option>
                          <option value="OWS">OWS</option>
                          <option value="GDS">GDS</option>
                          {this.hotel.meta.hasRewards === true && (
                            <option value="Rewards">Rewards</option>
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl variant="outlined">
                        <TextField
                          value={this.state.setRateFilter}
                          style={{ width: "100%", background: "white" }}
                          fullWidth
                          select
                          label="Set Rate"
                          variant="outlined"
                          name="setRateFilter"
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value="All">All</option>
                          <option value="set rate">Enabled</option>
                          <option value="without set rate">Disabled</option>
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <br />
                <GridDropZone
                  id="ratePlan"
                  boxesPerRow={4}
                  rowHeight={90}
                  disableDrag={
                    !this.isAdmin &&
                    filteredData.length === this.state.ratePlanArray.length
                      ? false
                      : true
                  }
                  disableDrop={
                    !this.isAdmin &&
                    filteredData.length === this.state.ratePlanArray.length
                      ? false
                      : true
                  }
                  // Remember to make it in round form
                  style={{
                    height: `${
                      Math.ceil(((filteredData.length / 4) * 100) / 100) * 100
                    }px`,
                  }}
                >
                  {filteredData.map((rp: any, index: any) => (
                    <GridItem key={rp.id}>
                      <div>{this.getCardForRP(rp, index)}</div>
                    </GridItem>
                  ))}
                </GridDropZone>
              </MuiThemeProvider>
            </Container>

            <br />
          </div>
        </GridContextProvider>

        {/* Delete Dialog for Rp */}
        <Popup
          isPopupOpen={this.state.isDeleteRpDialogOpened}
          title={`Delete ${this.state.rpToDelete.shortDescription}`}
          content={[
            "Please note:",
            "● This action is irreversible.",
            "● No changes will happen on the channel manager configuration.",
            "● This rate type will need to be deleted manually on the channel manager.",
            "Are you sure?",
          ]}
          action={() => {
            this.deleteRateplan(this.state.rpToDelete);
            this.closeDeleteDialog();
          }}
          actionName="Delete Rate Plan"
          cancelAction={this.closeDeleteDialog}
          cancelName="Cancel"
        />

        {/* Delete Dialog for Rt */}
        <Popup
          isPopupOpen={this.state.isDeleteRtDialogOpened}
          title={`Delete ${this.state.rtToDelete.shortDescription}`}
          content={[
            "Please note:",
            "● This action is irreversible.",
            "● No changes will happen on the channel manager configuration.",
            "● This room type will need to be deleted manually on the channel manager.",
            "Are you sure?",
          ]}
          action={() => {
            this.deleteRoomType(this.state.rtToDelete);
            this.closeDeleteDialog();
          }}
          actionName="Delete Room Type"
          cancelAction={this.closeDeleteRtDialog}
          cancelName="Cancel"
        />
      </MuiThemeProvider>
    );
  }
}

export default withRouter(RoomConfig);
