import { IYieldDay } from "./../../models/yield.models";
import moment from "moment";
import { CellCoords } from "../../models/yield.models";
import { HotTable } from "@handsontable-pro/react";
import { isBeforeToday } from "../yield-sheet/yield-sheet.helpers";
import { Rowprop, rowType } from "./rowProp";
import { hotel as gnHotel, restrictions } from "gn-shared";
import { appStore, AppStore } from "../store";

interface ContextMenuItem {
  name: string;
  key?: string;
  callback?: (
    name: string,
    selection: Array<{ start: CellCoords; end: CellCoords }>,
    clickEvent: any,
  ) => void;
  disabled: () => boolean;
  hidden: () => boolean;
  isCommand?: boolean;
  submenu?: any;
}

const losSubmenuCallback: Function = (
  type: string,
  key: string,
  selection: any,
  clickEvent: any,
  ys: any,
  numberOfDays: number,
) => {
  const action: "apply" | "remove" = !type.indexOf("APPLY_")
    ? "apply"
    : "remove";
  type = type.replace(/^[A-Z]*_/, "");
  if (selection.length === 1) {
    let cord = coordGenerator(selection[0].start, selection[0].end);
    if (cord.length > 1) {
      cord.forEach((item: any) => {
        ys.updateRestrictions(
          item.start,
          type as RestrictionTypeKeys,
          action,
          numberOfDays,
        );
      });
    } else {
      ys.updateRestrictions(
        selection[0].start,
        type as RestrictionTypeKeys,
        action,
        numberOfDays,
      );
    }
  }
  if (selection.length > 1) {
    selection.forEach((item: any) => {
      ys.updateRestrictions(
        item.start,
        type as RestrictionTypeKeys,
        action,
        numberOfDays,
      );
    });
  }
};

const OWSOverrideSubmenuCallback: Function = (
  label: string,
  key: string,
  selection: any,
  clickEvent: any,
  ys: any,
  numberOfDays: number,
) => {
  ys.bulkUpdateOwsOverride(selection, label);
};

const getMinLOSRestrictionSubmenuItems = (
  pkey: string,
  ys: any,
  losBoundaries: any,
) => {
  var menuItems = [];
  let b = losBoundaries[restrictions.TYPE.MIN_DAYS];
  for (let i = b.minActive; i <= b.maxActive; i++) {
    if (i == b.resetValue) {
      continue;
    }
    menuItems.push(
      getSubMenuItem("APPLY_MIN_DAYS", `${pkey}:${i}`, `+ ${i}`, ys, i),
    );
  }
  return menuItems;
};

function getSubMenuItem(
  type: string,
  key: string,
  name: string,
  ys: any,
  value: number,
): any {
  return {
    key,
    name,
    callback: (key: string, selection: any, clickEvent: any) => {
      losSubmenuCallback(type, key, selection, clickEvent, ys, value);
    },
  };
}

const getMaxLOSRestrictionSubmenuItems = (
  pkey: string,
  ys: any,
  losBoundaries: any,
) => {
  let menuItems = [];
  let b = losBoundaries[restrictions.TYPE.MAX_DAYS];
  for (let i = b.minActive; i <= b.maxActive; i++) {
    if (i == b.resetValue) {
      continue;
    }
    menuItems.push(
      getSubMenuItem("APPLY_MAX_DAYS", `${pkey}:${i}`, `+ ${i}`, ys, i),
    );
  }
  return menuItems;
};

export function getRestrictionsMenuItems(
  ys: any,
  rowProps: Rowprop,
  permissions: any,
  isDisabled: boolean | null,
  apiStore: AppStore,
): {
  [item: string]: ContextMenuItem;
} {
  if (isDisabled) {
    return {};
  }

  let hotel = gnHotel(
    appStore.meta.config.hotels[appStore.activeHotel.hotelId],
  );
  let id = hotel.cm.id;

  if (id === "guestline") {
    let items = {
      APPLY_STOP_SELL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Stop sell",

        "stop_sell" as RestrictionTypeKeys,
        null,
        true,
        null,
      ),
      REMOVE_STOP_SELL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "– Stop sell",

        "stop_sell" as RestrictionTypeKeys,
        null,
        false,
        null,
      ),

      APPLY_CLOSED_TO_ARRIVAL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Closed to arrival",

        "closed_to_arrival" as RestrictionTypeKeys,
        null,
        true,
        null,
      ),
      REMOVE_CLOSED_TO_ARRIVAL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "– Closed to arrival",

        "closed_to_arrival" as RestrictionTypeKeys,
        null,
        false,
        null,
      ),

      APPLY_CLOSED_TO_DEPARTURE: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Closed to departure",

        "closed_to_departure" as RestrictionTypeKeys,
        null,
        true,
        null,
      ),
      REMOVE_CLOSED_TO_DEPARTURE: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "– Closed to departure",

        "closed_to_departure" as RestrictionTypeKeys,
        null,
        false,
        null,
      ),
      APPLY_MIN_DAYS: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Minimum Length of Stay",

        "min_days" as RestrictionTypeKeys,
        getMinLOSRestrictionSubmenuItems,
        true,
        rowProps.globalParams.LOSBoundaries,
      ),
      REMOVE_MIN_DAYS: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "- Minimum Length of Stay",

        "min_days" as RestrictionTypeKeys,
        null,
        false,
        rowProps.globalParams.LOSBoundaries,
      ),
      OVERIDES_OWS: getOWSMenuItem(
        ys,
        rowProps,
        [rowType.actualOWSBaseRate],
        " OWS Override",
      ),
    };
    return items;
  } else {
    let items = {
      APPLY_STOP_SELL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Stop sell",

        "stop_sell" as RestrictionTypeKeys,
        null,
        true,
        null,
      ),
      REMOVE_STOP_SELL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "– Stop sell",

        "stop_sell" as RestrictionTypeKeys,
        null,
        false,
        null,
      ),

      APPLY_CLOSED_TO_ARRIVAL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Closed to arrival",

        "closed_to_arrival" as RestrictionTypeKeys,
        null,
        true,
        null,
      ),
      REMOVE_CLOSED_TO_ARRIVAL: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "– Closed to arrival",

        "closed_to_arrival" as RestrictionTypeKeys,
        null,
        false,
        null,
      ),

      APPLY_CLOSED_TO_DEPARTURE: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Closed to departure",

        "closed_to_departure" as RestrictionTypeKeys,
        null,
        true,
        null,
      ),
      REMOVE_CLOSED_TO_DEPARTURE: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "– Closed to departure",

        "closed_to_departure" as RestrictionTypeKeys,
        null,
        false,
        null,
      ),
      APPLY_MIN_DAYS: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Minimum Length of Stay",

        "min_days" as RestrictionTypeKeys,
        getMinLOSRestrictionSubmenuItems,
        true,
        rowProps.globalParams.LOSBoundaries,
      ),
      REMOVE_MIN_DAYS: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "- Minimum Length of Stay",

        "min_days" as RestrictionTypeKeys,
        null,
        false,
        rowProps.globalParams.LOSBoundaries,
      ),
      APPLY_MAX_DAYS: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "+ Maximum Length of Stay",

        "max_days" as RestrictionTypeKeys,
        getMaxLOSRestrictionSubmenuItems,
        true,
        rowProps.globalParams.LOSBoundaries,
      ),
      REMOVE_MAX_DAYS: getRestrictionsMenuItem(
        ys,
        rowProps,
        [rowType.roomType, rowType.ratePlan],
        "- Maximum Length of Stay",

        "max_days" as RestrictionTypeKeys,
        null,
        false,
        rowProps.globalParams.LOSBoundaries,
      ),
      OVERIDES_OWS: getOWSMenuItem(
        ys,
        rowProps,
        [rowType.actualOWSBaseRate],
        " OWS Override",
      ),
    };
    return items;
  }

  // horrible hack for production
}

function isDisabledMenu(
  instance: any,
  rowProps: Rowprop,
  restrictionType: RestrictionTypeKeys,
  applyiedRowType: string[],
): boolean {
  const hotInstance: HotTable["hotInstance"] = instance;
  if (hotInstance.getSelectedLast()[1] === 0) {
    return true;
  }
  const { row } = hotInstance.getSelectedRangeLast().highlight;
  return (
    applyiedRowType.indexOf(rowProps.get(row).type) == -1 ||
    rowProps.isRestrictionRestricted(row, restrictionType)
  );
}

type SubMenuGenerator = (key: string, ys: any, boundary: any) => any[];

function hasActiveRestrictionOfType(
  day: IYieldDay,
  rowprop: any,
  type: RestrictionTypeKeys,
  losBoundaries: any,
): boolean {
  try {
    let res;
    switch (rowprop.type) {
      case rowType.roomType:
        res = day.restrictions[rowprop.roomTypeId];
        if (
          res.restriction &&
          res.restriction[type] &&
          (res.restriction[type].active ||
            (res.restriction[type].days &&
              res.restriction[type].days != losBoundaries[type].resetValue))
        ) {
          return true;
        }
        return false;
      case rowType.ratePlan:
        if (
          !day.restrictions ||
          !day.restrictions[rowprop.roomTypeId] ||
          !day.restrictions[rowprop.roomTypeId].ratePlans
        ) {
          return false;
        }
        res =
          day.restrictions[rowprop.roomTypeId].ratePlans[rowprop.ratePlanId];
        if (
          res &&
          res.restriction &&
          res.restriction[type] &&
          (res.restriction[type].active ||
            (res.restriction[type].days &&
              res.restriction[type].days != losBoundaries[type].resetValue))
        ) {
          return true;
        }
        return false;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export function getOWSMenuItem(
  ys: any,
  rowProps: Rowprop,
  applyiedRowType: string[],
  name: string,
): ContextMenuItem {
  console.log("TOTO");
  let contextMenu: ContextMenuItem = {
    key: "OWSoverride",
    name,
    disabled() {
      return false;
    },
    hidden() {
      if (rowProps.owsFormulas.length == 0) {
        return true;
      }
      const hotInstance: HotTable["hotInstance"] = this;
      let col = hotInstance.getSelectedLast()[1];
      if (col === 0) {
        return true;
      }
      let day = ys.props.sheet[col - 1];

      if (isBeforeToday(day)) {
        return true;
      }
      const { row } = hotInstance.getSelectedRangeLast().highlight;
      const rowprop = rowProps.get(row);
      if (applyiedRowType.indexOf(rowprop.type) == -1) {
        return true;
      }
      return false;
    },
    callback: (itemName: any, selection: any) => {
      const action: "apply" | "remove" = !itemName.indexOf("APPLY_")
        ? "apply"
        : "remove";
      itemName = itemName.replace(/^[A-Z]*_/, "");
      ys.updateRestrictions(
        selection[0].start,
        itemName as RestrictionTypeKeys,
        action,
      );
    },
  };
  let items: any[] = [];
  let hotel = gnHotel(
    appStore.meta.config.hotels[appStore.activeHotel.hotelId],
  );
  let defaultLabel =
    typeof hotel.cm.channelConfig.ows.label != "undefined"
      ? hotel.cm.channelConfig.ows.label
      : "Default";
  items.push({
    key: "OWSoverride:" + defaultLabel,
    name: defaultLabel,
    callback: (key: string, selection: any, clickEvent: any) => {
      OWSOverrideSubmenuCallback(
        defaultLabel,
        key,
        selection,
        clickEvent,
        ys,
        0,
      );
    },
  });
  rowProps.owsFormulas.forEach((f, _) => {
    items.push({
      key: "OWSoverride:" + f.label,
      name: f.label,
      callback: (key: string, selection: any, clickEvent: any) => {
        OWSOverrideSubmenuCallback(f.label, key, selection, clickEvent, ys, 0);
      },
    });
  });

  contextMenu.submenu = { items };
  return contextMenu;
}

export function getRestrictionsMenuItem(
  ys: any,
  rowProps: Rowprop,
  applyiedRowType: string[],
  name: string,
  key: RestrictionTypeKeys,
  subMenuGenerator: SubMenuGenerator | null,
  hideIfpresent: boolean,
  losBoundaries: any | null,
): ContextMenuItem {
  let contextMenu: ContextMenuItem = {
    name,
    key: subMenuGenerator ? key : undefined,
    disabled() {
      const hotInstance: HotTable["hotInstance"] = this;
      const { row } = hotInstance.getSelectedRangeLast().highlight;
      let b = false;
      let a = isDisabledMenu(
        this,
        rowProps,
        key as RestrictionTypeKeys,
        applyiedRowType,
      );
      if (
        key === ("stop_sell" as RestrictionTypeKeys) &&
        rowProps.get(row).DOWCloseOut
      ) {
        //not the best code ever but will do the trick
        //TODO locked option
        let col = hotInstance.getSelectedLast()[1];
        let dayDate = ys.props.sheet[col - 1].hrDate;
        if (
          rowProps.get(row).DOWCloseOut.days.includes(moment(dayDate).day())
        ) {
          b = true;
        }
      }
      return a || b;
    },
    hidden() {
      const hotInstance: HotTable["hotInstance"] = this;
      let col = hotInstance.getSelectedLast()[1];
      if (col === 0) {
        return true;
      }
      let day = ys.props.sheet[col - 1];

      if (isBeforeToday(day)) {
        return true;
      }
      const { row } = hotInstance.getSelectedRangeLast().highlight;
      const rowprop = rowProps.get(row);
      if (applyiedRowType.indexOf(rowprop.type) == -1) {
        return true;
      }
      if (hasActiveRestrictionOfType(day, rowprop, key, losBoundaries)) {
        return hideIfpresent;
      } else {
        return !hideIfpresent;
      }
    },
    callback: (itemName: any, selection: any) => {
      const action: "apply" | "remove" = !itemName.indexOf("APPLY_")
        ? "apply"
        : "remove";
      itemName = itemName.replace(/^[A-Z]*_/, "");
      if (selection.length === 1) {
        let cord = coordGenerator(selection[0].start, selection[0].end);
        if (cord.length > 1) {
          cord.forEach((item: any) => {
            ys.updateRestrictions(
              item.start,
              itemName as RestrictionTypeKeys,
              action,
            );
          });
        } else {
          ys.updateRestrictions(
            selection[0].start,
            itemName as RestrictionTypeKeys,
            action,
          );
        }
      }
      if (selection.length > 1) {
        selection.forEach((item: any) => {
          ys.updateRestrictions(
            item.start,
            itemName as RestrictionTypeKeys,
            action,
          );
        });
      }
    },
  };
  if (subMenuGenerator) {
    contextMenu.submenu = { items: subMenuGenerator(key, ys, losBoundaries!) };
  }
  return contextMenu;
}

function coordGenerator(start: any, end: any) {
  let selCord = [];
  if (end.row > start.row) {
    for (let row = start.row; row <= end.row; row++) {
      selCord.push({ start: { row: row, col: start.col } });
    }
  }
  if (end.col > start.col) {
    for (let col = start.col; col <= end.col; col++) {
      selCord.push({ start: { row: start.row, col: col } });
    }
  }
  return selCord;
}
